import React from "react"
import { WidgetHintProps } from "../../types/widget.types"
import PuxTrumbowyg from "../shared/pux-trumbowyg"
import '../../sass/widgets/hint.scss'
import { Icon } from "../builders/icon-builder"


export default function WidgetHint(props: WidgetHintProps) {
    return (
        <div className={`hint-body`}>
            <div className="hint-icon">
                <Icon id="pux-blog" color="" />
            </div>
            <PuxTrumbowyg content={props.widgetData.hint} />
        </div>
    )
}