import React from 'react'
import { WidgetTopImageProps } from '../../types/widget.types'
import Picture from '../../components/shared/picture'
import PRight from '../../img/_zdroj-right.png'

export default function WidgetTopImage(props: WidgetTopImageProps) {
    const [isPictureLoaded, setPictuLoaded] = React.useState(false)

    const handlePictureLoad = () => {
        setPictuLoaded(true)
    }

    return props.widgetData.puxMediaSelector ? (
        <div className="top-image case">
            <div className="images-wrap">
                <div className="images">
                    <Picture
                        {...Object.assign({ ...props.widgetData.puxMediaSelector }, { alt: props.widgetData.displayText })}
                    />
                    <img className="right" src={PRight} alt="" onLoad={() => handlePictureLoad()} />
                </div>
            </div>
            <div className="content-wrap">
                <div className="pux-container">
                    <div className="content">
                        <h1>{props.widgetData.displayText}</h1>
                        <div
                            className="perex"
                            dangerouslySetInnerHTML={{
                                __html: props.widgetData.puxDesignTopImageDescription.html?.replace(/font-size: 1rem;/g, ``),
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div>No WidgetTopImage data</div>
    )
}
