import React, { useState } from 'react'
import { WidgetVideoProps } from '../../types/widget.types'
import FsLightbox from 'fslightbox-react';

export default function WidgetVideo(props: WidgetVideoProps) {
    const data = props.widgetData
    const [toggler, setToggler] = useState(false);

    // TODO Na mobilu bez autoplay play ikona spusti prehravani -> autoplay

    const videoSources = []
    const finalVideoSourceUrl: string = data.videoWidgetFile?.puxMediaSelectorImage?.resizePaths[0] ?? data.videoWidgetSourceUrl

    if (!finalVideoSourceUrl) return (
        <div>Missing Video Url</div>
    )

    videoSources.push(<iframe
        src={finalVideoSourceUrl}
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        style={{
            width: `90vw`,
            height: `${(90 / 16) * 9}vw`
        }}
    />)

    return (
        <div className="widget-video-player">
            {data.videoWidgetUseLightbox ?
                <>
                    <button className="lightbox-button" onClick={() => setToggler(!toggler)}>
                        <img
                            src={`${data.videoWidgetThumbnail.puxMediaSelectorImage.resizePaths}`}
                            srcSet={`${data.videoWidgetThumbnail.puxMediaSelectorImage.resizePathsMobile} 600w, ${data.videoWidgetThumbnail.puxMediaSelectorImage.resizePathsTablet} 1024w, ${data.videoWidgetThumbnail.puxMediaSelectorImage.resizePaths} 1600w`}
                        />
                    </button>
                    <FsLightbox
                        toggler={toggler}
                        sources={(videoSources as any) as string[]}
                    />
                </>
                :
                <>
                    <video
                        style={{
                            width: `100%`,
                            height: `auto`
                        }}
                        poster={`${data.videoWidgetThumbnail.puxMediaSelectorImage.resizePaths}`}
                        controls={data.videoWidgetHideUI ? false : true}
                        autoPlay={data.videoWidgetAutoplay}
                        muted={data.videoWidgetAutoplay}
                        loop={data.videoWidgetAutoplay}
                    >
                        {finalVideoSourceUrl.includes(".webm") ?
                            <source src={finalVideoSourceUrl} type="video/webm" />
                            :
                            <source src={finalVideoSourceUrl} type="video/mp4" />
                        }
                    </video>
                </>
            }
        </div>
    )
}