import React from "react"
import { WidgetTimelineProps } from "../../types/widget.types"
import { t } from 'ttag';
import SingleTimelineItem from "../SingleTimelineItem";


export default function Timeline(props: WidgetTimelineProps) {
    return (
        <div className="timeline">
            {
                props.widgetData.timelineWidgetItems.contentItems.map((item) => (
                    <SingleTimelineItem item={item}/>
                ))
            }
        </div>
    )
}


{/* <section className=''>
          <div className="pux-container">
            
          </div>
        </section> */}
