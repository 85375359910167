import React, { FunctionComponent } from 'react'
import { t } from 'ttag'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { TimelineItem } from '../types/widget.types'

interface TimelineItemProps {
    item: TimelineItem
}

const SingleTimelineItem: FunctionComponent<TimelineItemProps> = props => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    })

    const variants = {
        visible: { opacity: 1, scale: 1, x: 0 },
        hidden: {
            opacity: 0,
            x: 50,
        },
    }

    return (
        <motion.div
            ref={ref}
            className="timeline-item-wrap"
            key={props.item.contentItemId}
            animate={inView ? `visible` : `hidden`}
            variants={variants}
            transition={{ duration: 1, ease: `easeOut` }}
        >
            <div className="timeline-item">
                <div className="timeline-item-title">{props.item.displayText}</div>
                <div
                    className="timeline-item-description"
                    dangerouslySetInnerHTML={{ __html: props.item.timelineDescription.html?.replace(/font-size: 1rem;/g, ``) }}
                ></div>
            </div>
            <div className="timeline-item-info">
                <div className="left">
                    <div className="timeline-item-info-item">
                        <strong>{t`PuxDesign.Timeline.Location`}</strong>
                        {props.item.timelineLocation}
                    </div>
                    <div className="timeline-item-info-item">
                        <strong>{t`PuxDesign.Timeline.Count`}</strong>
                        {props.item.timelineCount}
                    </div>
                </div>
                <div className="right">
                    <div className="timeline-item-info-item">
                        <strong>{t`PuxDesign.Timeline.Turnover`}</strong>
                        {props.item.timelineTurnover}
                    </div>
                    <div className="timeline-item-info-item">
                        <strong>{t`PuxDesign.Timeline.Clients`}</strong>
                        {props.item.timelineClients}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default SingleTimelineItem
