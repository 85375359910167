import React from 'react'
import { WidgetCTABlockProps } from '../../types/widget.types'
import Picture from '../shared/picture'
import { Link } from 'gatsby'
import { getHrefLang, getLocalizedUrl } from '../../utils/localeURL'
import PuxTrumbowyg from '../shared/pux-trumbowyg'

export default function WidgetCTABlock(props: WidgetCTABlockProps) {
    const ctaBlock = props.widgetData

    function ctaBlockLink() {
        const ctaBlockLink = props.widgetData.cTABlockLink

        if (!ctaBlockLink) {
            return null
        }

        if (ctaBlockLink.internal[0]) {
            return (
                <Link className="btn landing-page-button" hrefLang={getHrefLang(ctaBlockLink.url[0])} to={getLocalizedUrl(ctaBlockLink.url[0])}>
                    {ctaBlockLink.text[0]}
                </Link>
            )
        } else {
            return (
                <a className="btn landing-page-button" href={ctaBlockLink.url[0]}>
                    {ctaBlockLink.text[0]}
                </a>
            )
        }
    }

    return ctaBlock ? (
        <div className="cta-block">
            {ctaBlock?.cTABlockWidgetImage?.puxMediaSelectorImage?.resizePaths?.length > 0 && (
                <div className="cta-block-images">
                    <Picture
                        puxMediaSelectorImage={ctaBlock.cTABlockWidgetImage.puxMediaSelectorImage}
                        alt=""
                        lazy={true}
                    />
                </div>
            )}
            <div className="cta-block-content">
                {ctaBlock?.cTABlockWidgetContent?.html && (
                    <PuxTrumbowyg content={ctaBlock.cTABlockWidgetContent} />
                )}
                {ctaBlockLink()}
            </div>
        </div>
    ) : (
        <div>No WidgetCTABlock data</div>
    )
}
