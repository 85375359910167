import React from 'react'
import { WidgetPersonsProps } from '../../types/widget.types'

export default function WidgetPersons(props: WidgetPersonsProps) {
  const persons = props.widgetData.puxDesignPersonsList?.contentItems

  return persons ? (
    <div className={persons.length > 4 ? `persons persons-big` : `persons`}>
      {persons.map((person, i) => (
        <div className="person-wrap" key={i}>
          <div className="person">
            <div className="person-img">
              <img src={person.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} width={80} height={80}></img>
            </div>
            <div className="person-name">{person.displayText}</div>
            <div className="person-phone">
              <a href={`tel:${person.puxDesignPersonPhone}`}>{person.puxDesignPersonPhone}</a>
            </div>
            <div className="person-mail">
              <a href={`mailto:${person.puxDesignPersonMail}`} className="btn-link">
                {person.puxDesignPersonMail}
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div>No WidgetPersons Data</div>
  )
}
