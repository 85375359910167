import React from "react"
import WidgetSection from '../widgets/widget-section'
import WidgetTrumbowyg from "../widgets/widget-trumbowyg"
import WidgetForm from "../widgets/widget-form"
import WidgetContainer from "./widget-container"
import WidgetCounters from "../widgets/widget-counters"
import WidgetButton from "../widgets/widget-button"
import WidgetImage from "../widgets/widget-image"
import WidgetTestimonial from "../widgets/widget-testimonial"
import WidgetPersons from "../widgets/widget-persons"
import WidgetTopImage from "../widgets/widget-top-image"
import WidgetLogos from "../widgets/widget-logos"
import WidgetIconsText from "../widgets/widget-icons-text"
import WidgetService from "../widgets/widget-service"
import WidgetReferences from "../widgets/widget-references"
import WidgetHint from "../widgets/widget-hint"
import WidgetTimeline from "../widgets/widget-timeline"
import { WidgetBuilderProps, WidgetData } from "../../types/widget.types"
import WidgetVideo from "../widgets/widget-video"
import WidgetTestimonialSlider from "../widgets/widget-testimonial-slider"
import WidgetLogoSlider from "../widgets/widget-logo-slider"
import WidgetCustomHTML from "../widgets/widget-customHTML"
import WidgetCTABlock from "../widgets/widget-CTA-block"

const buildWidget = (widgetData: WidgetData) => {
    switch (widgetData.contentType) {
        case "Section":
            return (<WidgetSection key={widgetData.contentItemId} widgetData={widgetData} />)
        case "Trumbowyg":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetTrumbowyg widgetData={widgetData} />
            </WidgetContainer>)
        case "Form":
            return (<WidgetForm key={widgetData.contentItemId} widgetData={widgetData} />)
        case "PuxDesignCounters":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetCounters widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignSlider":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetLogoSlider widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignTestimonialSlider":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetTestimonialSlider widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxButton":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetButton widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignImage":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetImage widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignTestimonial":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetTestimonial widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignPersons":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetPersons widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignTopImage":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetTopImage widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignLogos":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetLogos widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignIconsText":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetIconsText widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignService":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetService widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignReferences":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetReferences widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignHint":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetHint widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignTimelineWidget":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetTimeline widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignVideoWidget":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetVideo widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxCustomHTML":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetCustomHTML widgetData={widgetData} />
            </WidgetContainer>)
        case "PuxDesignCTABlockWidget":
            return (<WidgetContainer {...widgetData.widgetBasicProperties} {...widgetData.metadata} key={widgetData.contentItemId} >
                <WidgetCTABlock widgetData={widgetData} />
            </WidgetContainer>)
    }
}

export default function WidgetBuilder(props: WidgetBuilderProps) {
    const widgetBuilderData = props.widgetBuilderData ?? []
    return (
        <>
            {widgetBuilderData.map((widgetData: WidgetData) =>
                buildWidget(widgetData)
            )}
        </>
    )
}