import React from "react"
import { WidgetBasicProperites, WidgetMetadata } from "../../types/widget.types"

interface WidgetContainerProps extends WidgetBasicProperites, WidgetMetadata {
    children: React.ReactNode
}

interface WidgetAttributes {
    className: string
    id?: string
}

export default function WidgetContainer (props: WidgetContainerProps) {
    let className = 'widget-container';
    props.widgetBasicPropertiesMarginBottom ? className += ` ${props.widgetBasicPropertiesMarginBottom}` : null;
    props.widgetBasicPropertiesMarginTop ? className += ` ${props.widgetBasicPropertiesMarginTop}` : null;
    props.widgetBasicPropertiesPaddingBottom ? className += ` ${props.widgetBasicPropertiesPaddingBottom}` : null;
    props.widgetBasicPropertiesPaddingTop ? className += ` ${props.widgetBasicPropertiesPaddingTop}` : null;
    props.size ? className += ` w-${props.size}` : null;
    props.alignment ? className += ` ${props.alignment}` : null;

    const widgetAttributes : WidgetAttributes = {
        className: className
    };

    const [anchorClassName, setAnchorClassName] = React.useState('');

    React.useEffect(() => {
        if  (!!document.querySelector(".nav-will-render")) {
            setAnchorClassName("relative-anchor secondary-nav-helper")
        } else {
            setAnchorClassName("relative-anchor")
        }
    }, [])

    return <div {...widgetAttributes}>
        {props.widgetBasicPropertiesID ? <div id={props.widgetBasicPropertiesID} className={anchorClassName}></div> : null}
        {props.children}
    </div>
}