import React from "react"
import { WidgetCustomHTMLProps } from "../../types/widget.types"

export default function WidgetCustomHTML(props: WidgetCustomHTMLProps) {
    const html = props.widgetData?.customHTMLContent?.html

    return html ? (
        <div className={`custom-html`} dangerouslySetInnerHTML={{ __html: html }}>
        </div>
    ) : (
        <div>WidgetCounters has no data</div>
    )
}