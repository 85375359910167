import React from 'react'
import Slider from 'react-slick'
import { WidgetLogoSliderProps } from '../../types/widget.types'

export default function WidgetLogoSlider(props: WidgetLogoSliderProps) {
  const items = props.widgetData.bag.contentItems

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return items ? (
    <div className='logo-slider-wrapper'>
      <div className="logo-slider slick-slider-helper">
        <Slider {...settings}>
          {items.map((item, index) => (
            <div className="logo-slider-item" key={index}>
              <img loading='lazy' src={item.puxDesignSliderItemImage.resizePaths[0]} alt={item.displayText} />
            </div>
          ))}
        </Slider>

      </div>
    </div>
  ) : (
    <div>No WidgetLogoSlider Data</div>
  )
}
