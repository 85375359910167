import React from 'react'
import { WidgetLogosProps } from '../../types/widget.types'
import { getHrefLang, getLocalizedUrl } from '../../utils/localeURL'

export default function WidgetLogos(props: WidgetLogosProps) {
  const logos = props.widgetData?.bag?.contentItems

  return (
    <div className="logos">
      {logos?.map(logo =>
        logo.logoLink ? (
          <a href={logo.logoLink.internal[0] ? getLocalizedUrl(logo.logoLink?.url[0]) : logo.logoLink.url[0]} className="logo" target="_blank" rel="noopener" key={logo.contentItemId}>
            <img src={logo.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} alt={logo.displayText} />
          </a>
        ) : (
          <div className="logo" key={logo.contentItemId}>
            <img src={logo.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} alt={logo.displayText} />
          </div>
        )
      )}
    </div>
  )
}
