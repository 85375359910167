import React from 'react'
import { WidgetTrumbowygProps } from '../../types/widget.types'

export default function WidgetTrumbowyg(props: WidgetTrumbowygProps) {
  return (
    <div
      className={`trumbowyg-content-wrap${props.widgetData.isNarrow ? ` narrow` : ``}`}
      dangerouslySetInnerHTML={{ __html: props.widgetData.content.html?.replace(/font-size: 1rem;/g, ``) }}
    ></div>
  )
}
