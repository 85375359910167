import React from "react"
import { WidgetPuxImageProps } from "../../types/widget.types"

export default function WidgetImage(props: WidgetPuxImageProps) {
    return props.widgetData.puxMediaSelector ? (
        <picture>
            <source media="(min-width:1200px)" srcSet={props.widgetData.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} />
            <source media="(min-width:768px)" srcSet={props.widgetData.puxMediaSelector.puxMediaSelectorImage.resizePathsTablet[0]} />
            <source media="(min-width:320px)" srcSet={props.widgetData.puxMediaSelector.puxMediaSelectorImage.resizePathsMobile[0]} />
            <img src={props.widgetData.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} alt={props.widgetData.alt} />
        </picture>
    ) : (
        <div>No WidgetImage data</div>
    )
}


