import React from "react";
import { Link } from "gatsby";
import { WidgetReferencesProps, ReferenceWidgetItem } from "../../types/widget.types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Picture from '../shared/picture'
import { t } from 'ttag';
import { getLocalizedUrl } from "../../utils/localeURL";

const renderList = (references: [ReferenceWidgetItem]) => {
    return <div className='references'>
        {references.map(reference => {
            return <div className="reference-wrap" key={reference.contentItemId}>
                <Link to={getLocalizedUrl(reference
                    .puxDesignReferenceWidgetItemReference
                    .contentItems[0]?.path)}
                    className="reference"
                    key={reference.contentItemId}>
                    <div className="reference-img"
                        style={{
                            backgroundImage: `url('${reference
                                .puxDesignReferenceWidgetItemReference
                                .contentItems[0]?.puxDesignReferencePreviewImage
                                .puxMediaSelectorImage
                                .resizePaths[0]
                                }')`
                        }}
                    >
                        {
                            reference.puxDesignReferenceWidgetItemReference.contentItems[0]?.puxDesignReferenceType === 'casestudy' ?
                                <div className='case-study-tag'>{t`PuxDesign.CaseStudy`}</div> :
                                null
                        }
                    </div>
                    <div className="reference-text">
                        <div className="reference-title">
                            {reference.puxDesignReferenceWidgetItemCustomTitle ?
                                reference.puxDesignReferenceWidgetItemCustomTitle :
                                reference
                                    .puxDesignReferenceWidgetItemReference
                                    .contentItems[0]?.puxDesignReferenceDisplayTitle}
                        </div>
                        {/*
                        <div className="reference-description"
                            dangerouslySetInnerHTML={{
                                __html: reference.puxDesignReferenceWidgetItemCustomDescription?.html ?
                                    reference.puxDesignReferenceWidgetItemCustomDescription.html?.replace(/font-size: 1rem;/g, ``) :
                                    reference
                                        .puxDesignReferenceWidgetItemReference
                                        .contentItems[0]?.referencePreviewText.html?.replace(/font-size: 1rem;/g, ``)
                            }}
                        ></div>
                        */}
                    </div>
                </Link>
            </div>
        })}
    </div>
}

const renderSlider = (references: [ReferenceWidgetItem]) => {
    return <div className='references-slider'>
        <Carousel
            showStatus={false}
            showArrows={false}
            autoPlay={true}
            interval={3000}
            infiniteLoop={true}>

            {references.map(reference => <div className="reference-slide" key={reference.contentItemId}>
                {reference.puxDesignReferenceWidgetItemReference.contentItems[0] ?
                    <>
                        <div className='reference-slide-text-wrap'>
                            <div className="reference-info">
                                {reference
                                    .puxDesignReferenceWidgetItemReference
                                    .contentItems[0]?.puxDesignReferenceType === 'casestudy' ?
                                    <span className='pr-20'>{t`PuxDesign.CaseStudy`}</span> : ''}
                                {reference
                                    .puxDesignReferenceWidgetItemReference
                                    .contentItems[0]?.referenceCategory?.termContentItems[0]?.displayText ?
                                    <span className='category' data-display={reference
                                        .puxDesignReferenceWidgetItemReference
                                        .contentItems[0]?.referenceCategory?.termContentItems[0]?.displayText}>{reference
                                        .puxDesignReferenceWidgetItemReference
                                        .contentItems[0]?.referenceCategory?.termContentItems[0]?.displayText}
                                    </span> : ''}
                            </div>
                            <h3 className="reference-title">
                                {reference
                                    .puxDesignReferenceWidgetItemCustomTitle ?
                                    reference
                                        .puxDesignReferenceWidgetItemCustomTitle :
                                    reference
                                        .puxDesignReferenceWidgetItemReference
                                        .contentItems[0]?.puxDesignReferenceDisplayTitle}
                            </h3>
                            {/*
                            <div className="reference-description"
                                dangerouslySetInnerHTML={{
                                    __html: reference
                                        .puxDesignReferenceWidgetItemCustomDescription?.html ?
                                        reference
                                            .puxDesignReferenceWidgetItemCustomDescription.html?.replace(/font-size: 1rem;/g, ``) :
                                        reference
                                            .puxDesignReferenceWidgetItemReference
                                            .contentItems[0]?.referencePreviewText.html?.replace(/font-size: 1rem;/g, ``)
                                }}
                            ></div>
                            */}
                            <Link to={getLocalizedUrl(reference
                                .puxDesignReferenceWidgetItemReference
                                .contentItems[0]?.path)}
                                className='btn btn-default'>
                                {t`PuxDesign.CaseStudy.Read`}
                            </Link>
                        </div>
                        <div className='reference-slide-img-wrap'>
                            <Picture puxMediaSelectorImage={reference
                                .puxDesignReferenceWidgetItemReference
                                .contentItems[0]?.puxDesignReferenceLaptopImage.puxMediaSelectorImage}
                                alt={reference
                                        .puxDesignReferenceWidgetItemReference
                                        .contentItems[0]?.puxDesignReferenceDisplayTitle} />
                        </div>
                    </> : null}
            </div>)}

        </Carousel>
    </div>
}

export default function WidgetReferences(props: WidgetReferencesProps) {
    const references = props.widgetData?.bag?.contentItems;
    const isSlider = props.widgetData.puxDesignReferencesIsSlider;


    if (references && references.length) {
        return isSlider ? renderSlider(references) : renderList(references);
    } else {
        return null;
    }
}