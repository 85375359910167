import React, { ReactComponentElement } from "react"
import WidgetBuilder from "../builders/widget-builder"
import { WidgetSectionProps } from "../../types/widget.types"

export default function WidgetSection(props: WidgetSectionProps) {
    let className = props.widgetData.backgroundDefinition ? (props.widgetData.backgroundDefinition.color ? props.widgetData.backgroundDefinition.color : '') : '';
    props.widgetData.triangleCross ? className += ' triangle-cross' : null;

    const { template } = props.widgetData;

    if (props.widgetData.template?.length > 1) {
        props.widgetData.flow.widgets.sort((widget1, widget2) => {
            const index1 = template.findIndex(x => x.contentItemId === widget1.contentItemId);
            const index2 = template.findIndex(x => x.contentItemId === widget2.contentItemId);
            return index1 - index2;
        })
    }
    

    const content = (
        <div className={`widgets-container${props.widgetData.noSpacing ? ' no-spacing' : ''}`}>
            <WidgetBuilder widgetBuilderData={props.widgetData.flow.widgets} />
        </div>
    )

    if (props.widgetData.useContainer) {
        return (
            <section className={className}>
                <div className='pux-container'>
                    {content}
                </div>
            </section>
        )
    } else {
        return (
            <section className={className}>
                {content}
            </section>
        )
    }
}