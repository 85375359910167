import React from 'react'
import { WidgetIconsTextProps } from '../../types/widget.types'
import { Icon } from '../builders/icon-builder'
import WidgetButton from '../widgets/widget-button'
import WidgetTestimonial from '../widgets/widget-testimonial'
import WidgetContainer from '../builders/widget-container'

export default function WidgetIconsText(props: WidgetIconsTextProps) {
    const variantSelector = props.widgetData?.puxDesignVariantSelector?.puxDesignVariantSelector

    const items = props.widgetData?.bag?.contentItems
    return (
        <div className={`icons-text${props.widgetData.puxDesignIconsTextSmallerIcons ? ` smaller-icons` : ``}${variantSelector ? ` ` + variantSelector : ``}`}>
            {items.map(item => (
                <div className="icon-text-wrap" key={item.contentItemId}>
                    <div className="icon-text">
                        <div className="icon">
                            <Icon
                                id={item.puxDesignIconSelector.puxDesignIconSelectorIcon}
                                color={item.puxDesignIconSelector.puxDesignIconSelectorColor}
                            ></Icon>
                        </div>
                        <div
                            className="text"
                            dangerouslySetInnerHTML={{ __html: item.puxDesignIconTextText.html?.replace(/font-size: 1rem;/g, ``) }}
                        ></div>
                    </div>
                    {item.iconTextButton?.contentItems.length > 0 ? (
                        <WidgetContainer
                            {...item.iconTextButton?.contentItems[0].widgetBasicProperties}
                            {...item.iconTextButton?.contentItems[0].metadata}
                        >
                            <WidgetButton widgetData={item.iconTextButton?.contentItems[0]} />
                        </WidgetContainer>
                    ) : null}
                    {item.iconTextTestimonial?.contentItems.length > 0 ? (
                        <WidgetContainer
                            {...item.iconTextTestimonial?.contentItems[0].widgetBasicProperties}
                            {...item.iconTextTestimonial?.contentItems[0].metadata}
                        >
                            <WidgetTestimonial widgetData={item.iconTextTestimonial?.contentItems[0]} />
                        </WidgetContainer>
                    ) : null}
                </div>
            ))}
        </div>
    )
}
