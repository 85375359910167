import React from 'react'
import { WidgetServiceProps } from '../../types/widget.types'
import { t } from 'ttag'
import { getHrefLang, getLocalizedUrl } from '../../utils/localeURL'

const getInfo = (link: string, tooltip: string) => {
  if (link) {
    return (
      <a href={getLocalizedUrl(link)} target="_blank">
        {t`PuxDesign.PDFPricelist`}
      </a>
    )
  } else if (tooltip) {
    return <span data-tip={tooltip}>{t`PuxDesign.MoreAboutPrice`}</span>
  } else {
    return ``
  }
}

export default function WidgetService(props: WidgetServiceProps) {
  const info = getInfo(props.widgetData.puxDesignServicePDF.urls[0], props.widgetData.puxDesignServiceTooltip.html)

  const btnRef = React.useRef<HTMLSpanElement>(null)

  const handleServiceClick = (): void => {
    if (btnRef !== null && btnRef.current !== null) {
      let formSelectValue: string | null = btnRef.current.getAttribute(`data-form-value`)

      if (formSelectValue === null) {
        formSelectValue = `1`
      }

      const formEl: HTMLElement | null = document.getElementById(`sib-form-scroll-helper`)
      const selectEl: HTMLSelectElement | null = document.querySelector(`.sib-select select`)

      if (selectEl !== null) {
        selectEl.value = formSelectValue
      }

      if (formEl !== null) {
        formEl.scrollIntoView({ behavior: `smooth` })
      }
    }
  }

  return (
    <div className="service">
      <h3>{props.widgetData.puxDesignServiceService}</h3>
      <div className="service-price">
        <span>{props.widgetData.puxDesignServicePriceFrom}</span>
        {info ? ` — ` : ``}
        {info}
      </div>
      <div
        className="perex"
        dangerouslySetInnerHTML={{
          __html: props.widgetData.puxDesignServiceDescription.html?.replace(/font-size: 1rem;/g, ``),
        }}
      ></div>
      <span
        className="btn btn-primary"
        ref={btnRef}
        onClick={() => handleServiceClick()}
        data-form-value={props.widgetData.puxDesignServiceBTNLink}
      >
        {props.widgetData.puxDesignServiceBTNText}
      </span>
    </div>
  )
}
