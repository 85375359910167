import React from 'react'
import { WidgetPuxButtonProps } from '../../types/widget.types'
import { Link } from 'gatsby'
import { getHrefLang, getLocalizedUrl } from '../../utils/localeURL'

export default function WidgetButton(props: WidgetPuxButtonProps) {
  const className = props.widgetData.type + (props.widgetData.size ? ` ${props.widgetData.size}` : ``)
  const buttonLink = props.widgetData.puxButtonLink

  if (buttonLink?.url.length) {
    if (buttonLink.internal[0]) {
      return (
        <Link hrefLang={getHrefLang(buttonLink.url[0])} to={getLocalizedUrl(buttonLink.url[0])} className={className}>
          {buttonLink.text[0]}
        </Link>
      )
    } else {
      return (
        <a href={buttonLink.url[0]} className={className} {...props.widgetData.target}>
          {buttonLink.text[0]}
        </a>
      )
    }
  } else {
    return <div>No WidgetButton data</div>
  }
}
