import React from 'react'
import { WidgetTestimonialProps } from '../../types/widget.types'

export default function WidgetTestimonial(props: WidgetTestimonialProps) {
    const person = props.widgetData.puxDesignTestimonialPerson?.contentItems[0]

    return person ? (
        <div className="testimonial">
            { person.puxMediaSelector.puxMediaSelectorImage.resizePaths[0] != undefined &&
                <div className="testimonial-image">
                    <img
                        width={60}
                        height={60}
                        src={person.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]}
                        alt={person.displayText}
                    />
                </div>
            }
            <div className="testimonial-content">
                <div
                    className="quotation"
                    dangerouslySetInnerHTML={{
                        __html: props.widgetData.puxDesignTestimonialQuotation.html?.replace(/font-size: 1rem;/g, ``),
                    }}
                ></div>
                <div className="person">
                    <div className="person-name">{person.displayText}</div>
                    <div className="person-position">
                        {person.puxDesignPersonPosition ? ` / ${person.puxDesignPersonPosition}` : ``}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div>No WidgetTestimonial person data</div>
    )
}
