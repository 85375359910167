import React from "react"
import { WidgetCountersProps } from "../../types/widget.types"
import { InView } from 'react-intersection-observer';
import CountUp from 'react-countup';

export default function WidgetCounters(props: WidgetCountersProps) {
    const variantSelector = props.widgetData?.puxDesignVariantSelector?.puxDesignVariantSelector

    return props.widgetData.bag ? (
        <div className={`counters${variantSelector ? ` ` + variantSelector : ``}`}>
            {props.widgetData.bag.contentItems.map((item, i) => (
                <div className="counter-item" key={i}>
                    {/* triggerOnce={true} */}
                    <InView as="div">
                        {({ inView, ref }) => (
                            <>
                                <strong ref={ref}>
                                    {inView ?
                                        <CountUp delay={0} start={0} end={item.puxDesignCounterItemNumber} duration={6}>
                                            {({ countUpRef }) => (
                                                <span ref={countUpRef} className='number' />
                                            )}
                                        </CountUp>
                                        : <span className='numberff'>0</span>
                                    }
                                    {item.puxDesignCounterItemBoldText}
                                </strong>
                                <div>{item.puxDesignCounterItemDescription}</div>
                            </>
                        )}
                    </InView>
                </div>
            ))}
        </div>
    ) : (
        <div>WidgetCounters has no data</div>
    )
}