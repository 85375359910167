import React from "react"
import { WidgetFormProps } from "../../types/widget.types"
import FormBuilder from "../builders/form-builder"

export default function WidgetForm(props: WidgetFormProps) {
    const colClass = (width: number): string => {
        switch (width) {
            case 100:
                return "col-12"
                break
            case 50:
                return "col-6"
                break
            default:
                return ""
        }
    }
    return (
        // <section
        //     className={colClass(props.widgetData.metadata.size)}
        //     id={props.widgetData.contentItemId}
        // >
        //     <FormBuilder formBuilderData={props.widgetData} />
        // </section>
        <div></div>
    )
}