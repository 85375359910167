import React from 'react'
import { WidgetTestimonialSliderProps } from '../../types/widget.types'
import Slider from 'react-slick'

export default function WidgetTestimonialSlider(props: WidgetTestimonialSliderProps) {
  const items = props.widgetData.bag.contentItems

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className='mb-30' dangerouslySetInnerHTML={{ __html: props.widgetData.puxDesignTestimonialSliderPerex.html }}></div>
      <div className="testimonial-slider slick-slider-helper">
        <Slider {...settings}>
          {items && items.map((item, index) => (
            <div className="testimonial-slider-item" key={index}>
              <div className='testimonial-slider-item-image'>
                <img loading='lazy' src={item.puxDesignTestimonialSliderItemImage.resizePaths[0]} alt={item.displayText} />
              </div>
              <div className='testimonial-slider-item-perex' dangerouslySetInnerHTML={{ __html: item.puxDesignTestimonialSliderItemText.html }}></div>
              <div className='testimonial-slider-item-name'>{item.puxDesignTestimonialSliderItemName}</div>
              <div className='testimonial-slider-item-position'>{item.puxDesignTestimonialSliderItemPosition}</div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}